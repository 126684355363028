<template>
<div>
  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="banks"
      :search="search"
      sort-by="nombre"
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
                <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid">
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model.trim="editedItem.name"
                          label="Nombre Completo"
                          :rules="editedItem.nameRules"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="450">
            <v-card>
              <v-card-title>
                <span>Desea eliminar a {{editedItem.name}}?</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-actions>
                <v-btn color="green darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="green darken-1" text @click="eliminar">Aceptar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-icon color="success" small class="mr-2" @click="editItem(item)">fas fa-edit</v-icon>
        <v-icon color="red" small @click="deleteItem(item)">fas fa-trash-alt</v-icon>
      </template>
      
    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
export default {
  name: "drivers",
  data: () => ({
    dialog: false,
    banks: [],
    dialogDelete: false,
    search: "",
    loading: "",
    titulo: "Conductor",
    headers: [
      { text: "Nombre", align: "left", value: "name" },
      { text: "Acciones", value: "action", sortable: false }
    ],
    editedIndex: -1,

    valid: true,
    editedItem: {
      id: "",
      name: "",
      nameRules: [v => !!v || "Escriba un nombre"],
      address: "",
      active: false
    },
    defaultItem: {
      name: "",
      nameRules: [v => !!v || "Escriba un nombre"],
      address: "",
      active: false
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    ...mapState(["db","headers_db"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions(["validateSession"]),
    // Metodos base de datos
    async getBanks(){
      await axios.get(this.db + 'drivers',
        {
          headers: this.headers_db
        }).then(response => {
          this.banks = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getBanks(); })
        });
    },

    addProcess(payload){
      axios.post(this.db + 'driver/add', {
        rtn: payload.rtn,
        name: payload.name,
        address: payload.address,
      },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.getBanks();
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.addProcess(payload); })
      });

    },
    updateProcess(payload){

      axios.post(this.db + 'driver/update', {
        id: payload.id,
        name: payload.name,
      },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response.data.message);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        //this.getBanks();
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.updateProcess(payload); })
      });

    },

    deleteProcess(id){

      axios.post(this.db + 'driver/delete', {
        id
      },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.banks = this.banks.filter(doc => {
        return doc.id != id
        })
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.deleteProcess(id); })
      });

    },
    // FIN Metodos base de datos


    // Metodos Generales

    editItem(item) {
      console.log(item);
      this.editedIndex = this.banks.indexOf(item);
      this.dialog = true;
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      console.log(item.id);

      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.dialogDelete = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.banks[this.editedIndex], this.editedItem);
          this.updateProcess(this.editedItem);
        } else {
          this.banks.push(this.editedItem);
          this.addProcess(this.editedItem);
        }
        this.close();
      }
    },

    eliminar() {
      this.deleteProcess(this.editedItem.id);
      this.close();
    },
  },
  created() {
    this.getBanks();
  }
};
</script>

